import { Component, DestroyRef, EventEmitter, Inject, Injector, Input, Output, forwardRef } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { BaseField } from '../base-field';
import { SelectOption } from '@parashift/shared/utils';
import { ValidationErrorsComponent } from 'shared/components/forms/validation-errors/validation-errors.component';

@Component({
  selector: 'select-field',
  templateUrl: './select-field.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, AsyncPipe, NgIf, NgForOf, ValidationErrorsComponent],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectFieldComponent), multi: true }]
})
export class SelectFieldComponent extends BaseField {

  @Input({ required: true })
  set options(options: SelectOption[] | any[] | Observable<SelectOption[] | any[]>) {
    this._options = options instanceof Observable ? options : of(options);
  }
  get options(): Observable<SelectOption[] | any[]> { return this._options; }
  private _options: Observable<SelectOption[] | any[]>;

  @Input() labelProp: string;
  @Input() valueProp: string;
  @Input() description!: string;

  @Output() fieldChanged = new EventEmitter<FormControl>();

  constructor(
    injector: Injector,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(injector, destroyRef);
  }

  onChange() {
    this.fieldChanged.emit(this.control);
  }
}
