<div class="form-group">
  <label *ngIf="label" [for]="'field-component-' + formControlName + (controlContainer?.name ? '_' + controlContainer.name : '')">{{ label }}<span *ngIf="required"> *</span></label>
  <select
    [formControl]="control"
    [id]="'field-component-' + formControlName + (controlContainer?.name ? '_' + controlContainer.name : '')"
    [required]="required"
    (change)="onChange()"
    class="form-control form-control-sm form-select"
  >
    <option *ngFor="let option of options | async" [ngValue]="valueProp ? option[valueProp] : option.value" [disabled]="readonly || option.disabled">{{ labelProp ? option[labelProp] : option.label }}</option>
  </select>
  <validation-errors *ngIf="control?.invalid && (control?.dirty || control?.touched)" [errors]="control?.errors" [label]="label" class="invalid-feedback"></validation-errors>
  <small *ngIf="description" class="form-text text-muted">{{ description }}</small>
</div>
